import en from "@/locales/en.json";
import "@gohighlevel/ghl-ui/dist/style.css";
import * as Sentry from "@sentry/vue";
import { setupSentry } from "common/util/setupSentry";
import { createPinia } from "pinia";
import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import { router } from "./router";
import "./style.css";

export const i18n = createI18n({
  locale: "en",
  globalInjection: true,
  legacy: false,
  runtimeOnly: true,
  messages: {
    en,
  },
});

const app = createApp(App);
setupSentry(Sentry, app, router, process.env.NODE_ENV as string);
app.use(createPinia());
app.use(i18n);
app.use(router);
app.mount("#app");
