<script setup lang="ts">
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://vuejs.org/api/sfc-script-setup.html#script-setup
import { UIContentWrap } from "@gohighlevel/ghl-ui";
import Postmate from "postmate";
import { onMounted } from "vue";
import { useAppStore } from "./store/app";
import { setupPendo } from "./util/pendoConfig";
import * as Sentry from "@sentry/vue";
import { setSentryMeta } from "common/util/setupSentry";

onMounted(() => {
  const appStore = useAppStore();

  if (process.env.NODE_ENV === "development") {
    Postmate.debug = true;
  }

  const handshake = new Postmate.Model({
    "refresh-token": (token: string) => appStore.refreshToken(token),
    "abort-deletion": (data: object) => appStore.abortDeletion(data),
  });

  handshake.then(({ model }: any) => {
    appStore.initialize(model);
    appStore.setAppHandshake(handshake);
    setupPendo(model);
    setSentryMeta(Sentry, {
      userId: model.userId,
      companyId: model.companyId,
      locationId: model.locationId,
      email: model.userEmail,
      locale: "en-US",
    });
  });
});
</script>

<template>
  <UIContentWrap class="bg-white">
    <router-view></router-view>
  </UIContentWrap>
</template>

<style scoped></style>
