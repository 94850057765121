{
  "links": {
    "triggerLinksDescription": "Trigger links allow you to put links inside SMS messages and emails, which allow you to track specific customer actions and trigger events based on when the link is clicked.",
    "enterName": "Enter name",
    "enterLinkURL": "Enter Link URL",
    "save": "Save",
    "cancel": "Cancel",
    "delete": "Delete",
    "aboutToDeleteLink": "`You're about to delete the ${deleteRowName} trigger link. Deleted link cannot be restored, are you sure you want to continue?`",
    "noTriggerLinksFound": "No trigger links found",
    "getStartedByAddingNewTriggerLinks": "Get started by adding new trigger links",
    "newTriggerLink": "New Trigger Link",
    "searchNoMatch": "Your search did not match any trigger link.",
    "link": "Link",
    "addLink": "Add Link",
    "linkURL": "Link URL",
    "name": "Name",
    "pleaseEnterNonEmptyLinkName": "Please enter a non-empty link name",
    "pleaseEnterURL": "Please enter URL",
    "actions": "Actions"
  }
}
