import { defineStore } from "pinia";
import Postmate from "postmate";

export interface AppStore {
  locationId: string;
  userEmail: string;
  userId: string;
  token: string;
  appHandshake: Postmate.Model | undefined;
  companyId: string;
  timezone: string;
  appType: "STANDALONE" | "EMBED";
  requestRetryCount: number;
  authTokenV2: string;
}

export const useAppStore = defineStore("app", {
  state: (): AppStore => ({
    locationId: (import.meta.env.VITE_LOCATION_ID as string) || "yv04YeYVJHCw8LSGmNW4",
    userId: (import.meta.env.VITE_USER_ID as string) || "",
    userEmail: (import.meta.env.VITE_USER_EMAIL as string) || "",
    token: (import.meta.env.VITE_TOKEN_ID as string) || "",
    appType: import.meta.env.VITE_APP_TYPE || "STANDALONE",
    companyId: "",
    timezone: "",
    appHandshake: undefined,
    requestRetryCount: 0,
    authTokenV2: "",
  }),
  actions: {
    updateRequestRetryCount(count: number) {
      this.requestRetryCount = count;
    },
    initialize(payload: AppStore) {
      this.locationId = payload.locationId;
      this.userId = payload.userId;
      this.userEmail = payload.userEmail;
      this.token = payload.token;
      this.authTokenV2 = payload.authTokenV2;
      this.companyId = payload.companyId;
      this.timezone = payload.timezone;
      this.appType = payload.appType;
    },
    refreshToken(newToken: string) {
      this.token = newToken;
    },
    abortDeletion(data: object) {
      console.log(data);
    },
    setAppHandshake(payload: any) {
      console.log("setAppHandshake", payload);
      this.appHandshake = payload;
    },
    updateLocationId(payload: string) {
      this.locationId = payload;
    },
  },
});
