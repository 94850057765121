import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const HomePage = () => import("@/pages/Home.vue");
const TriggerLinksPage = () => import("@/pages/TriggerLinksPage.vue");

export const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/v2/location/:locationId/links",
    component: TriggerLinksPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export { router };
